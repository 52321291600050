import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles/styles.scss';
import '@fortawesome/fontawesome-free/css/all.css';
import {RouterProvider} from 'react-router-dom';
import router from './router';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { I18nextProvider } from 'react-i18next';
import i18 from './i18n';
import { Suspense } from 'react';

const root = ReactDOM.createRoot(document.getElementById('root'));

const loadingMarcup = (
  <div className='d-flex justify-content-center align-items-center'>
    <h1>Loading...</h1>
  </div>
)
root.render(
  <Suspense fallback={loadingMarcup}>

  <React.StrictMode>
      <I18nextProvider i18n={i18}>
         <RouterProvider router={router}/>
      </I18nextProvider>
  </React.StrictMode>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

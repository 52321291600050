import Error from "../images/error.png";
import Close from "../images/close.png";
const PopUp = ({ errorMessage, onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup">
            <img className="error-image" src={Error} alt="error" />
         {/* <a href="" className="dismiss-button" onClick={onClose}>
          <span>&times;</span>
        </a> */}
        <button onClick={onClose} className="close-button"><img src={Close} alt="close" /></button>
        <p className="error-message">{errorMessage}</p>
        <button className="ok-button" onClick={onClose}>
          OK
        </button> 
      </div>
    </div>
  );
};

export default PopUp;

import { createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import Layout from "./components/Layout";
import OrderTrack from "./pages/OrderTrack";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "/privacy",
        element: <Privacy />,
      },

      {
        path: "/track_your_order",
        element: <OrderTrack />,
      },
    ],
  },
]);

export default router;

import { useRef, useEffect, useState } from "react";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";
import ReactFlagsSelect from "react-flags-select";
import logo from "../logo.png";

const Header = () => {
  //Vars

  const { t } = useTranslation();
  const currentLanguage = cookies.get("i18next") || "en";
  const [currentDirection, setCurrentDirection] = useState(i18next.dir);

  const navbarHeight = 10 * 10; //10rem * fontsize(10px)

  // Refs
  const navBarRef = useRef(null);
  //functions
  const handleScroll = () => {
    navBarRef.current?.classList.remove("active");
  };

  const handleSectionNav = () =>{
    if (window.location.pathname !== "/") {
      window.location.href = "/";
    }
  };

  //Effects
  useEffect(() => {
    document.title = t("Sa'ee");
    document.body.dir = currentDirection;
  }, [currentLanguage,t,currentDirection]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //functions

  const toggleActiveNavbar = () => {
    navBarRef.current?.classList.toggle("active");
  };
  const handleLanguageChange = (countryCode) => {
    if (countryCode === "SA") {
      i18next.changeLanguage("ar");
      setCurrentDirection("rtl");
    } else if (countryCode === "US") {
      i18next.changeLanguage("en");
      setCurrentDirection("ltr");
    }
  };

  return (
    <header className="header">
      <a href="/" className="logo">
        <img src={logo} alt="" />
      </a>

      <nav className="navbar" ref={navBarRef}>
        <div className="languages-dropdown" dir="ltr">
          <ReactFlagsSelect
            selected={currentLanguage === "ar" ? "SA" : "US"}
            onSelect={handleLanguageChange}
            countries={["SA", "US"]}
            customLabels={{ SA: "عربى", US: "English" }}
          />
        </div>
        <Link
          to="home-section"
          offset={-navbarHeight}
          smooth={true}
          duration={100}
          className="navbar-link"
          spy
          onClick={()=>handleSectionNav()}
        >
          {t("Home")}
        </Link>
        <Link
          to="about-section"
          offset={-navbarHeight}
          smooth={true}
          duration={100}
          className="navbar-link"
          spy
          onClick={()=>handleSectionNav()}
        >
          {t("About")}
        </Link>
        <Link
          to="features-section"
          offset={-navbarHeight}
          smooth={true}
          duration={100}
          className="navbar-link"
          spy
          onClick={()=>handleSectionNav()}
        >
          {t("Features")}
        </Link>
        <Link
          to="technologies-section"
          offset={-navbarHeight}
          smooth={true}
          duration={100}
          className="navbar-link"
          spy
          onClick={()=>handleSectionNav()}
        >
          {t("Our Technology")}
        </Link>
        <Link
          to="faq-section"
          offset={-navbarHeight}
          smooth={true}
          duration={100}
          className="navbar-link"
          spy
          onClick={()=>handleSectionNav()}
        >
          {t("FAQs")}
        </Link>
        <Link
          to="contact-section"
          offset={-navbarHeight}
          smooth={true}
          duration={100}
          className="navbar-link"
          spy
          onClick={()=>handleSectionNav()}
        >
          {t("Connect")}
        </Link>
      </nav>

      <div className="icons">
        <div
          id="menu-btn"
          className="fa fa-bars"
          onClick={toggleActiveNavbar}
        ></div>
      </div>
    </header>
  );
};

export default Header;

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Background from "../images/privacy.png";
import config from "../config";
import Loader from "../components/Loader";
const Privacy = () => {
  const appUrl = config.appUrl;
  const {t} = useTranslation();

  const currentLanguage = i18next.language;

  const [isLoading, setIsLoading] = useState(false);

  const [htmlContent, setHtmlContent] = useState(``);

  useEffect(() => {
    fetchContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchContent = () => {
    setIsLoading(true);
    fetch(`${appUrl}/api/privacy_policy`)
      .then((response) => response.json())
      .then((data) => {
        if (currentLanguage === 'ar') {
          setHtmlContent(data.privacy_policy.privacy_policy_ar);
        } else {
          setHtmlContent(data.privacy_policy.privacy_policy_en);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      })
      .catch((error) => {
        console.error("Error fetching content:", error);
        // setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="privacy">
          <div className="slide">
            <div className="overlay"></div>
            <img src={Background} alt="privacy" />
            <h3 className="title">{t('Privacy Policy')}</h3>
          </div>
          <section className="content">
            <div style={{all:'unset',}}  dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </section>
        </div>
      )}
    </>
  );
};

export default Privacy;

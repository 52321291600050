import { useTranslation } from "react-i18next";

const Footer = () => {

    const {t} = useTranslation();

    return ( 
        <footer className="footer">
            <div class="copy-right"><p>Copyright © 2023 Sa’ee Logistics. All rights reserved</p></div>
            <div className="privacy-link">
                <a href="/privacy">
                    <p>{t('Privacy Policy')}</p>
                </a>
            </div>
        </footer>   
     );
}
 
export default Footer;
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import { Element } from "react-scroll";
import "swiper/swiper-bundle.css";
import { useEffect, useRef, useState } from "react";
import FAQ from "../images/FAQs-rafiki.png";
import Laptop from "../images/laptop.png";
// import Dashboards from "../images/dashboards.png";
import Next from "../images/next.png";
import Prev from "../images/prev.png";
import Phone from "../images/phone.png";
import Email from "../images/email.png";
import Location from "../images/location.png";
import Facebook from "../images/facebook.png";
import Instagram from "../images/instagram.png";
import config from "../config";
import Loader from "../components/Loader";
import PopUp from "../components/PopUp";
import Countries from "../components/countries.json";
const Home = () => {
  //Vars
  const appUrl = config.appUrl;
  const {t} = useTranslation();

  const currentLanguage = i18next.language;
  // const currentDirection = i18next.dir;
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [sliderContent, setSliderContent] = useState([]);
  const [sliderFeatures, setSliderFeatures] = useState([]);
  const [sliderTechnologies, setSliderTechnologies] = useState([]);
  const [websiteInfo, setWebsiteInfo] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    title: "",
    body: "",
  });
  const [aboutContent, setAboutContent] = useState(``);
  const countryOptions = Countries.countries;
  const [selectedCountry, setSelectedCountry] = useState("+962");
  //Vars ends
  
  //Refs
  const laptopSwiperRef = useRef(null);
  //Refs ends

  //Effects
  // useEffect(()=>{
  //   document.body.dir = currentDirection;
  //   // alert(currentDirection);
  // },[currentDirection]);
  useEffect(() => {
    fetchContent(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);
  //Effects ends

  //funcitons

  const closePopup = () => {
    setShowPopup(false);
    setErrorMessage("");
  };

  const fetchContent = () => {
    setIsLoading(true);
    fetch(`${appUrl}/api/website-data`)
      .then((response) => response.json())
      .then((data) => {
        setSliderContent(data.sliders);
        setSliderTechnologies(data.technologies);
        setSliderFeatures(data.features);
        setFaqs(data.faq);
        if (currentLanguage === 'ar') {
          setAboutContent(data.aboutus_ar);
        } else {
          setAboutContent(data.aboutus_en);
        }
        const website = {
          email: data.email,
          address: data.address,
          phone1: data.number1,
          phone2: data.number2,
          facebook: data.facebook,
          instagram: data.instagram,
          image: data.image,
        };
        setWebsiteInfo(website);
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      })
      .catch((error) => {
        console.error("Error fetching content:", error);
      });
  };
  
  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedFormData = {
      ...formData,
      number: `${selectedCountry}${formData.number}`,
    };

    fetch(`${appUrl}/api/website/contact_us`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedFormData),
    })
      .then((response) => {
        if (response.ok) {
          alert(t('Your message was sent successfuly'));
          setFormData({
            name: "",
            email: "",
            number: "",
            title: "",
            body: "",
          });
        } else {
          response.json().then((data) => {  
            const translatedErrorMessages = Object.keys(data.errors).reduce((acc, key) => {
              const fieldErrorMessages = data.errors[key].map(errorMessage => t(errorMessage));
              return [...acc, ...fieldErrorMessages];
            }, []);
            
            const errorMessageString = translatedErrorMessages.join(', ');
            setErrorMessage(errorMessageString);
            setShowPopup(true);
          });
          throw new Error("Failed to send message.");
        }
      })
      .catch((error) => {
        console.error("Error sending message:", error);
      });
  };
  //functions ends

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="home">
          <Element name="home-section">
            <div className="main-slider-container">
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={50}
                slidesPerView={1}
                scrollbar={{ draggable: true }}
                className="main-slider"
                loop
                autoplay={{ delay: 3500 }}
                speed={500}
                effect="slide"
              >
                {sliderContent.map((slide, index) => (
                  <SwiperSlide key={index} className="slide">
                    <div className="overlay"></div>
                    <img
                      src={`${appUrl}/storage/sliders/${slide.image}`}
                      alt={slide.image}
                    />
                    <div className="content">
                      <h3>{currentLanguage ==='ar' ?slide.title_ar : slide.title_en}</h3>
                      <p>{currentLanguage ==='ar' ? slide.description_ar : slide.description_en}</p>
                      <a href={slide.action}>{index ? t('Explore Now') : t('Track Order')}</a>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Element>

          <Element name="about-section">
            <section className="about-us">
              <Container className="">
                <Row xs={1} md={2}>
                  <Col className="box">
                    <h3>{t('About Us')}</h3>
                    <p>
                      {aboutContent}
                    </p>
                  </Col>
                  <Col className="box1">
                    <img
                      src={`${appUrl}/storage/aboutUs/${websiteInfo.image}`}
                      alt="Saee"
                    />
                  </Col>
                </Row>
              </Container>
            </section>
          </Element>

          <Element name="features-section">
            <section className="features" dir="ltr">
              <h3 className="heading">{t('Features')}</h3>
              <Swiper
                className="features-slider"
                draggable
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={50}
                centerInsufficientSlides
                pagination={{ clickable: true }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  1200: {
                    slidesPerView: 4,
                  },
                }}
                loop
                autoplay
                speed={500}
                effect="slide"
              >
                {sliderFeatures.map((slide, index) => (
                  <SwiperSlide key={index} className="slide">
                    <div className="feature-card">
                      <img
                        src={`${appUrl}/storage/features/${slide.image}`}
                        alt={slide.image}
                      />
                      <h5>{currentLanguage ==='ar' ? slide.title_ar : slide.title_en}</h5>
                      <p>{currentLanguage ==='ar' ? slide.description_ar : slide.description_en}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </section>
          </Element>

          <Element name="technologies-section">
            <section className="used-technologies">
              <h3 className="heading">{t('USED TECHNOLOGIES')}</h3>
              <div className="slider">
                <div className="red-bar"></div>
                <button
                  onClick={() => laptopSwiperRef.current.swiper.slideNext()}
                  className="next-button"
                >
                  <img src={Next} alt="next" />
                </button>
                <button
                  onClick={() => laptopSwiperRef.current.swiper.slidePrev()}
                  className="prev-button"
                >
                  <img src={Prev} alt="prev" />
                </button>
                <div className="laptop-frame" dir="ltr">
                  <img src={Laptop} alt="laptop" />
                  <Swiper
                    className="swiper-container-ha"
                    ref={laptopSwiperRef}
                    modules={[
                      Navigation,
                      Pagination,
                      Scrollbar,
                      A11y,
                      Autoplay,
                    ]}
                    spaceBetween={1}
                    slidesPerView={1}
                    loop
                    autoplay
                    speed={500}
                    effect="slide"
                  >
                    {sliderTechnologies.map((slide, index) => (
                      <SwiperSlide key={index} className="centered-slide">
                        <img
                          src={`${appUrl}/storage/technologies/${slide.image}`}
                          alt={slide.image}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </section>
          </Element>

          <Element name="faq-section">
            <section className="faq">
              <Container>
                <Row xs={1} md={2}>
                  <Col className="box1">
                    <img src={FAQ} alt="faq" />
                  </Col>

                  <Col className="box2">
                    <Accordion defaultActiveKey="0" className="faq-accordion">
                      {faqs.map((faq, index) => (
                        <Accordion.Item
                          key={index}
                          eventKey={index}
                          className="item"
                        >
                          <Accordion.Header>
                            <h5>{currentLanguage ==='ar' ? faq.question_ar : faq.question_en}</h5>
                          </Accordion.Header>
                          <Accordion.Body>
                            <p>{currentLanguage ==='ar' ? faq.answer_ar : faq.answer_en}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </Col>
                </Row>
              </Container>
            </section>
          </Element>

          <Element name="contact-section">
            <section className="contactus">
              <div className="box1">
                <div className="contact-informations">
                  <div className="info-box">
                    <div className="item">
                      <div className="icon">
                        <img src={Phone} alt="phone" />
                      </div>
                      <div className="info">
                        <h5>{t('PHONE NUMBER')}</h5>
                        <a href={`tel:${websiteInfo.phone1}`}>
                          {websiteInfo.phone1}
                        </a>
                        <a href={`tel:${websiteInfo.phone2}`}>
                          {websiteInfo.phone2}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="info-box">
                    <div className="item">
                      <div className="icon">
                        <img src={Email} alt="phone" />
                      </div>
                      <div className="info">
                        <h5>{t('Email')}</h5>
                        <a href={`mailto:${websiteInfo.email}`}>
                          {websiteInfo.email}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="info-box">
                    <div className="item">
                      <div className="icon">
                        <img src={Location} alt="phone" />
                      </div>
                      <div className="info">
                        <h5>{t('Location')}</h5>
                        <p>{websiteInfo.address}</p>
                      </div>
                    </div>
                  </div>
                  <div className="social">
                    <a href={websiteInfo.instagram} className="icon">
                      <img src={Instagram} alt="instagram" />
                    </a>
                    <a href={websiteInfo.facebook} className="icon">
                      <img src={Facebook} alt="facebook" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="box2">
                <h3 className="heading">{t('GET IN TOUCH')}</h3>
                <form onSubmit={handleSubmit}>
                  <Container>
                    <Row>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder={t('Name')}
                        className="name"
                        onChange={handleChange}
                        value={formData.name}
                      />
                    </Row>

                    <Row className="number-row">
                    <select
                        className="prefix"
                        value={selectedCountry}
                        onChange={handleCountryChange}
                      >
                        {countryOptions.map((country,key) => (
                          <option key={key}  value={country.code}>
                            {`${country.code} - ${country.name}`}
                          </option>
                        ))}
                      </select>
                      <input
                        className="phone-input"
                        type="text"
                        name="number"
                        id="number"
                        placeholder={t('Phone Number')}
                        onChange={handleChange}
                        value={formData.number}
                      />
                    </Row>

                    <Row>
                      <input
                        type="text"
                        name="email"
                        id="email"
                        placeholder={t('Email')}
                        onChange={handleChange}
                        value={formData.email}
                      />
                    </Row>

                    <Row>
                      <input
                        type="text"
                        name="title"
                        id="title"
                        placeholder={t('Subject')}
                        onChange={handleChange}
                        value={formData.title}
                      />
                    </Row>

                    <Row>
                      <textarea
                        name="body"
                        id="body"
                        rows={3}
                        placeholder={t('Type message here ...')}
                        onChange={handleChange}
                        value={formData.body}
                      ></textarea>
                    </Row>

                    <Row>
                      <button type="submit">{t('Submit')}</button>
                    </Row>
                  </Container>
                </form>
              </div>
            </section>
          </Element>
          {showPopup && <PopUp errorMessage={t(errorMessage)} onClose={closePopup} />}
        </div>
      )}
    </>
  );
};

export default Home;

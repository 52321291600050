import { useTranslation } from "react-i18next";
import Background from "../images/track.png";
import Inprogress from "../images/in_progress.png";
import OutOfDelivery from "../images/out_of_delivery.png";
import OutOfDeliveryNone from "../images/out_of_delivery_none.png";
import Delivered from "../images/delivered.png";
import DeliveredNone from "../images/delivered_none.png";
import Rejected from "../images/rejected.png";
import { Container, Row } from "react-bootstrap";
import { Suspense, useState } from "react";
import { scroller, Element } from "react-scroll";
import Countries from "../components/countries.json";
// import Swal from 'sweetalert2';

import config from "../config";
import PopUp from "../components/PopUp";
// import Loader from "../components/Loader";
const OrderTrack = () => {
  const appUrl = config.appUrl;
  const {t} = useTranslation();

  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSections, setShowSections] = useState(false);
  const [refCode, setRefCode] = useState("");
  const [isRefCodeValid, setIsRefCodeValid] = useState(false);
  const [hasFocused, setHasFocused] = useState(false);
  const [order, setOrder] = useState("");
  const [orderAssigned , setOrderAssigned] = useState(false);
  const [estimated, setEstimated] = useState("");
  const [dropDate , setDropDate] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    title: "",
    body: "",
    ref_code: "",
  });
  const countryOptions = Countries.countries;
  const [selectedCountry, setSelectedCountry] = useState("+962");

  const closePopup = () => {
    setShowPopup(false);
    setErrorMessage("");
  };

  const scrollToSection = (sectionName) => {
    scroller.scrollTo(sectionName, {
      duration: 50,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChangeCode = (e) => {
    const value = e.target.value;
    setRefCode(value);
    value.length === 10 && hasFocused
      ? setIsRefCodeValid(true)
      : setIsRefCodeValid(false);
    setFormData({ ref_code: value });
  };

  const handleSendCode = (e) => {
    e.preventDefault();
    // setIsLoading(true);
    const requestBody = {
      ref_code: refCode,
    };
    fetch(`${appUrl}/api/track_order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
            return response.json().then((data) => {
            const errorMessages = data.errors.ref_code;
            throw new Error(t(errorMessages.join('\n')));  
          });
        }
      })
      .then((data) => {
        console.log(data);
        setOrder(data.order.status);
        if(data.order.drop_of_driver_id){
          setOrderAssigned(true);
        }
        setShowSections(true);
        const estimatedDate = new Date(data.order.pickup_date);
        estimatedDate.setDate(estimatedDate.getDate() + 2);
        const options = { year: "numeric", month: "short", day: "numeric" };
        const formattedDate = estimatedDate.toLocaleDateString(
          "en-US",
          options
        );
        const fetchedDropDate = new Date(data.order.drop_date);
        const formatedDropDate = fetchedDropDate.toLocaleDateString(
          "en-US",
          options
        );
        setDropDate(formatedDropDate);
        setEstimated(formattedDate);
        setTimeout(() => {
          scrollToSection("timeline-section");
        }, 1);
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage(error.message);
        setShowPopup(true);
      });

    // setTimeout(()=>{
    //   setIsLoading(false)
    // },500)
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedFormData = {
      ...formData,
      number: `${selectedCountry}${formData.number}`,
    };

    fetch(`${appUrl}/api/website/contact_us`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedFormData),
    })
      .then((response) => {
        if (response.ok) {
          alert(t('Your message was sent successfuly'));
          setFormData({
            name: "",
            email: "",
            number: "",
            title: "",
            body: "",
            ref_code: "",
          });
        } else {
          response.json().then((data) => {  
            const translatedErrorMessages = Object.keys(data.errors).reduce((acc, key) => {
              const fieldErrorMessages = data.errors[key].map(errorMessage => t(errorMessage));
              return [...acc, ...fieldErrorMessages];
            }, []);
            
            const errorMessageString = translatedErrorMessages.join(', ');
            setErrorMessage(errorMessageString);
            setShowPopup(true);
          });
          throw new Error("Failed to send message.");
        }
      })
      .catch((error) => {
        console.error("Error sending message:", error);
      });
  };


  return (
    <Suspense fallback={(
      <div style={{color:"white",backgroundColor:"#700c00",height: "100vh", width:"100vw", display:"flex" , flexWrap:"wrap", justifyContent: "center", alignItems: "center"}}>
        <h1 style={{fontWeight:"bolder"}}>Loading.....</h1>
      </div>
    )}>
      <div className="order-track">
      <>
        <div className="slide">
          <div className="overlay"></div>
          <img src={Background} alt="privacy" />
          <div className="centered-box">
            <h3>{t('Track Your Order')}</h3>
            <p>{t('Enter Your Reference Code')}</p>
            <form onSubmit={handleSendCode}>
              <div className="input-container">
                <input
                  type="text"
                  placeholder={t('Please Enter the 10 digits ref code')}
                  className={
                    isRefCodeValid ? "valid" : hasFocused ? "invalid" : ""
                  }
                  value={refCode}
                  onChange={handleChangeCode}
                  name="ref_code"
                  maxLength={20}
                  onFocus={() => {
                    setHasFocused(true);
                  }}
                  onBlur={() => {
                    setHasFocused(false);
                  }}
                />
                <button
                  type="submit"
                  className="check-btn"
                  // disabled={!isRefCodeValid}
                >
                  {t('Check!')}
                </button>
              </div>
              {hasFocused && !isRefCodeValid && (
                <p className="error-message">{t('Please enter exactly 10 digits.')}</p>
              )}
            </form>
            <div className="estimated">
              {order === 'requested' || order === 'confirmed' ? <p>{t('pending')}....</p> :
              order === 'delivered' ?
              <p>
                {dropDate && (
                  <>
                    <b>{t('Delivered at:')} </b> {dropDate}
                  </>
                )}
              </p>
              :
              order === 'rejected'?
              <p>
                {dropDate && (
                  <>
                    <b>{t('Rejected at:')} </b> {dropDate}
                  </>
                )}
              </p>
              :
              <p>
                {estimated && (
                  <>
                    <b>{t('Estimated at:')} </b> {estimated}
                  </>
                )}
              </p>
              }
              
            </div>
          </div>
        </div>

        {showSections && (
          <>
            <Element name="timeline-section">
              <section className="timeline">
                <div className="timeline-container">
                  <div className="content">
                    {orderAssigned ? (<div className="line-left-red"></div>) : (<div className="line-left-grey"></div>)}
                    {order === "delivered" || order === "rejected" ? (<div className="line-right-red"></div>) : (<div className="line-right-grey"></div>)}
                    <div className="inprogress">
                      <p className="title">{t('In Progress')}</p>
                      {/* {order !== "rejected" ? ( */}
                        <img src={Inprogress} alt={t('In Progress')} />
                      {/* ) : ( */}
                        {/* <img src={InprogressNone} alt="In Progress" /> */}
                      {/* )} */}
                    </div>
                    <div className="out-of-delivery">
                      <p className="title">{t('Out For Delivery')}</p>
                      { orderAssigned === true || order === 'rejected'? (
                        <img src={OutOfDelivery} alt={t('Out For Delivery')} />
                      ) : (
                        <img src={OutOfDeliveryNone} alt={t('Out For Delivery')}/>
                      )}
                    </div>
                    <div className="delivered">
                      {order === "delivered" ? (
                        <>
                        <p className="title">{t('Delivered')}</p>
                        <img src={Delivered} alt={t('Delivered')} />
                        </>
                      ) : order === "rejected" ? (
                        <>
                         <p className="title">{t('Rejected')}</p>
                        <img src={Rejected} alt={t('Rejected')} />
                        </>
                      ) : (
                        <>
                         <p className="title">{t('Delivered')}</p>
                        <img src={DeliveredNone} alt={t('Not delivered')} />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </Element>

            <section className="contact-us">
              <div className="poly"></div>
              <h3 className="heading">{t('GET IN TOUCH')}</h3>
              <Container>
                <Row>
                  <div className="box">
                    <form onSubmit={handleSubmit}>
                      <Container>
                        <Row>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder={t('Name')}
                            className="name"
                            onChange={handleChange}
                            value={formData.name}
                          />
                        </Row>

                        <Row className="number-row">
                    <select
                        className="prefix"
                        value={selectedCountry}
                        onChange={handleCountryChange}
                      >
                        {countryOptions.map((country,key) => (
                          <option key={key}  value={country.code}>
                            {selectedCountry === country.code ? country.code : `${country.code} - ${country.name}`}
                          </option>
                        ))}
                      </select>
                      <input
                        className="phone-input"
                        type="text"
                        name={t('number')}
                        id="number"
                        placeholder={t('Phone Number')}
                        onChange={handleChange}
                        value={formData.number}
                      />
                    </Row>

                        <Row>
                          <input
                            type="text"
                            name="email"
                            id="email"
                            placeholder={t('Email')}
                            onChange={handleChange}
                            value={formData.email}
                          />
                        </Row>

                        <Row>
                          <input
                            type="text"
                            name="title"
                            id="title"
                            placeholder={t('Subject')}
                            onChange={handleChange}
                            value={formData.title}
                          />
                        </Row>

                        <Row>
                          <textarea
                            name="body"
                            id="body"
                            rows={3}
                            placeholder={t('Type message here ...')}
                            onChange={handleChange}
                            value={formData.body}
                          ></textarea>
                        </Row>

                        <Row>
                          <button type="submit">{t('Submit')}</button>
                        </Row>
                      </Container>
                    </form>
                  </div>
                </Row>
              </Container>
            </section>
          </>
        )}
      </>
      {showPopup && <PopUp errorMessage={t(errorMessage)} onClose={closePopup} />}
    </div>
    </Suspense>
    
  );
};

export default OrderTrack;
